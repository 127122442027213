// Own ProjectForge Styles
// ========================================================================

table {
  width: auto;

  th {
    background: #f2f2f2;
  }

  th:first-child, td:first-child {
    padding-left: 10px;
  }

  th:last-child, td:last-child {
    padding-right: 10px;
  }
}

pre {
  color: #b90505;
  font-size: .775rem;

  &.highlight {
    padding: 15px;
    background: #f4f4f4;
  }
}

.uk-navbar-item.uk-logo img {
  width: 90px;
  margin-right: 20px;
}

.uk-card.uk-card-default p {
  font-size: 16px;
}

.uk-navbar-dropdown {
  padding: 10px 20px;
  top: 60px !important;
}

.uk-navbar-nav > li > a {
  font-weight: 700;
}

.uk-container-xsmall {
  h4 {
    margin-bottom: 10px;
  }

  * + p, * + ul, * + ol, * + dl, * + pre, * + address, * + fieldset, * + figure {
    margin-top: 10px;
  }
}

.uk-icon-link {
  color: #686a75;
}

p.hero-image img {
  max-height: 500px;
}

p a,
li a,
table td a {
  color: #BF4040;
}

// FAQ Layout

#pf-faq .uk-section {
  padding-top: 0;
  padding-bottom: 20px;

  .uk-accordion {

    li a.uk-accordion-title {
      border-bottom: 1px solid #ebecee !important;
      font-weight: 600;
      padding: 10px;
      font-size: 16px;
    }

    > :nth-child(n+2) {
      margin-top: 10px;
    }
  }
}

// Code View Layout

.listingblock {
  pre code table {
    margin: 0;
  }

  pre code pre {
    margin: 0;
    border: none;
  }

  table.linenotable td {
    margin: 0;
    padding: 0;
  }
}

