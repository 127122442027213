// System fonts
@import "system-font-css/_system-font";

// Custom variables and variable overwrites.
@import "theme/variables";

// Import default variables and available mixins.
@import "uikit/variables-theme";
@import "uikit/mixins-theme";

// Custom mixin overwrites.
@import "theme/mixins";

// Import UIkit.
@import "theme/uikit";

// Other vendor styles
// @import "syntax-highlighting/cld";
@import "syntax-highlighting/bw";

// Own ProjectForge Styles
// Overwrite Docs Theme Styles

@import "theme/projectforge";
@import "tipuesearch/tipuesearch";

@font-face {
  font-family: "Assistant";
  font-display: swap;
  src: url(/assets/fonts/Assistant-VariableFont_wght.woff2) format("woff2");
}