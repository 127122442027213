// Docs Theme Mixins
// ========================================================================

@mixin hook-base-body(){
    //filter: blur(7px);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@mixin hook-base-misc(){
    input[type="submit" i]{
        -webkit-appearance:none;
    }
    .link-primary a:not(.lightbox):not(.uk-inline):not(.uk-link-muted):not(.uk-slidenav) {
        transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
        border-bottom: 1px solid $global-primary-background;
        color: $global-primary-background;        
    }

    .link-primary a:not(.lightbox):not(.uk-inline):not(.uk-link-muted):not(.uk-slidenav):hover {
        border-bottom-color: lighten($global-primary-background, 25%);
        text-decoration: none;
    }

    .link-secondary a:not(.lightbox):not(.uk-inline):not(.uk-link-muted):not(.uk-slidenav):hover {
        border-bottom: 1px solid $global-color;     
        text-decoration: none;
    }
}

@mixin hook-navbar(){
    font-weight: 500;
    .uk-drop {
        width: 360px;
    }
    .uk-search-navbar .uk-search-input {
        height: 50px;
        font-size: 1.0625rem;
        padding-left: 1.1875rem;
        background: #fff;
    }
}

@mixin hook-navbar-misc(){
    .uk-navbar-item .uk-button {
        padding: 0 22px;
        line-height: 34px;
    }
    // Fix for UIkit left and right first & last items
    .uk-navbar-container > .uk-container {
        .uk-navbar-left {
            margin-left: -$navbar-nav-item-padding-horizontal;
        }
        .uk-navbar-right {
            margin-right: -$navbar-nav-item-padding-horizontal;
        }
    }
}

@mixin hook-logo(){
    font-weight: 700;
}

@mixin hook-button(){
    border-radius: 30px;
}

@mixin hook-button-misc(){
    // Warning
    .uk-button-warning {
        background-color: $button-warning-background;
        color: $button-primary-color;
        border: 1px solid transparent;
    }

    .uk-button-warning:hover,
    .uk-button-warning:focus {
        background-color: $button-warning-hover-background;
        color: $button-primary-color;
    }

    .uk-button-warning:active,
    .uk-button-warning.uk-active {
        background-color: $button-warning-hover-background;
        color: $button-primary-color;
    }

    // Success
    .uk-button-success {
        background-color: $button-success-background;
        color: $button-primary-color;
        border: 1px solid transparent;
    }

    .uk-button-success:hover,
    .uk-button-success:focus {
        background-color: $button-success-hover-background;
        color: $button-primary-hover-color;
    }

    .uk-button-success:active,
    .uk-button-success.uk-active {
        background-color: $button-success-hover-background;
        color: $button-primary-active-color;
    }

    // Primary outline
    .uk-button-primary-outline {
        background-color: $button-default-background;
        color: $button-primary-background;
        border: $button-border-width solid $button-primary-background;
    }

    .uk-button-primary-outline:hover,
    .uk-button-primary-outline:focus {
        background-color: $button-primary-background;
        color: $button-primary-hover-color;
        border: $button-border-width solid $button-primary-background;
    }

    .uk-button-primary-outline:active,
    .uk-button-primary-outline.uk-active {
        background-color: $button-primary-background;
        color: $button-primary-active-color;
        border: $button-border-width solid $button-primary-background;
    }

    // Danger outline
    .uk-button-danger-outline {
        background-color: $button-default-background;
        color: $button-danger-background;
        border: $button-border-width solid $button-danger-background;
    }

    .uk-button-danger-outline:hover,
    .uk-button-danger-outline:focus {
        background-color: $button-danger-background;
        color: $button-danger-hover-color;
        border: $button-border-width solid $button-danger-background;
    }

    .uk-button-danger-outline:active,
    .uk-button-danger-outline.uk-active {
        background-color: $button-danger-background;
        color: $button-danger-active-color;
        border: $button-border-width solid $button-danger-background;
    }

    // Success outline
    .uk-button-success-outline {
        background-color: $button-default-background;
        color: $button-success-background;
        border: $button-border-width solid $button-success-background;
    }

    .uk-button-success-outline:hover,
    .uk-button-success-outline:focus {
        background-color: $button-success-background;
        color: $button-primary-hover-color;
        border: $button-border-width solid $button-success-background;
    }

    .uk-button-success-outline:active,
    .uk-button-success-outline.uk-active {
        background-color: $button-success-background;
        color: $button-primary-hover-color;
        border: $button-border-width solid $button-success-background;
    }

    // Warning outline
    .uk-button-warning-outline {
        background-color: $button-default-background;
        color: $button-warning-background;
        border: $button-border-width solid $button-warning-background;
    }

    .uk-button-warning-outline:hover,
    .uk-button-warning-outline:focus {
        background-color: $button-warning-background;
        color: $button-primary-hover-color;
        border: $button-border-width solid $button-warning-background;
    }

    .uk-button-warning-outline:active,
    .uk-button-warning-outline.uk-active {
        background-color: $button-warning-background;
        color: $button-primary-active-color;
        border: $button-border-width solid $button-warning-background;
    }
    
}

@mixin hook-alert(){
    border-radius: $border-rounded-border-radius;
}

@mixin hook-alert-misc(){

    .uk-alert h1,
    .uk-alert h2,
    .uk-alert h3,
    .uk-alert h4,
    .uk-alert h5,
    .uk-alert h6 { color: inherit; }

    .uk-alert a:not([class]) {
        color: inherit !important;
        border-bottom-color: inherit !important;
    }

    .uk-alert a:not([class]):hover {
        color: inherit !important;
        border-bottom-color: inherit !important;
    }
}

@mixin hook-card-body(){
    > p {
        font-size: $global-small-font-size;
    }
    > span {
        color: $global-primary-background;
    }
}

@mixin hook-card-misc(){
    .uk-position-cover {
        z-index: 1;
    }
    .card-category {
        h3:nth-child(2n) {
            margin-top: 0 !important;
        }
    }
    .card-post {
        .uk-card-header{
            padding-top: $global-medium-margin;
            padding-bottom: 0;
            border-bottom: none;
            .avatar {
                width: 40px;
            }
        }
        .uk-card-body{
            padding-top: $global-medium-margin / 2;
            padding-bottom: $global-medium-margin / 2;
        }
        .uk-card-footer{
            padding-bottom: $global-medium-margin * 0.9;
            padding-top: 0;
            border-top: none;
        }
    }
}

@mixin hook-form(){
    border: solid 1px $global-border;
}

@mixin hook-offcanvas-bar(){
    font-weight: 500;
}

@mixin hook-list-misc(){
    .uk-list {
        margin: 0;
    }
}

@mixin hook-article-title(){
    margin-bottom: $global-margin;
}

@mixin hook-article-meta(){
    a {
        color: $article-meta-color;
        &:hover {
            color: $global-primary-background;
        }
    }
    .avatar {
        margin-right: 15px;
        max-width: 36px;
    }
}

@mixin hook-article-misc(){
    .article-content {
        line-height: 1.8;

        figure img, .uk-slideshow-items {
            border: solid 1px $border-xlight;
            box-shadow: $global-medium-box-shadow;
            border-radius: $border-radius-small;
        }
        figure {
            img + div .uk-overlay-icon {
                color: rgba(255, 255, 255, 0);
            }
            img:hover + div .uk-overlay-icon {
                color: rgba(255, 255, 255, 1);
            }
            figcaption {
                margin-left: 0;
                span {
                    padding-right: 20px;
                    margin-bottom: -43px;
                    margin-top: 20px;
                    border-right: solid 2px $global-muted-color;
                    font-style: italic;
                    font-size: 0.8rem;
                    line-height: 1.8;
                }
            }
        }
        blockquote {
            border-left: solid 2px $global-muted-color;
            padding-left: $base-margin-vertical;
            line-height: 1.7;
        }

        figure, .uk-slideshow {
            margin-top: $global-medium-margin;
            margin-bottom: $global-medium-margin;
        }

        figure figcaption {
            margin-bottom: $global-medium-margin * 1.8;
        }

        ul:not(#markdown-toc):not(#markdown-toc ul):not(.uk-slideshow-items),
        .uk-alert {
            margin-bottom: $global-medium-margin;
        }

        table {
            margin-top: $global-medium-margin * .7;
            margin-bottom: $global-medium-margin;
        }
        
        pre,
        blockquote {
            margin-top: $global-medium-margin * .7;
            margin-bottom: $global-medium-margin;
        }
            
    }
    .avatar {
        border-radius: 50%;
    }
    .paginate-post .uk-text-small {
        line-height: 1.75;
    }
}

@mixin hook-table-misc() {
    table {
        font-size: $global-small-font-size;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin-bottom: $global-medium-margin;
        >tr:not(:first-child), >:not(:first-child)>tr, >:first-child>tr:not(:first-child) {
            border-top: 1px solid $table-divider-border;
        }  
        tbody tr {
            transition: background-color .1s linear;
        }
        th, td {
            padding: 10px 12px;
        }  
        td {
            vertical-align: top;
        }  
        th {
            text-align: left;
            vertical-align: bottom;
        }
        th:first-child, td:first-child {
            padding-left: 0;
        }  
        th:last-child, td:last-child {
            padding-right: 0;
        }
    }         
}

@mixin hook-label(){
    font-weight: 500;
    border-radius: $label-border-radius;
}

@mixin hook-navbar-sticky(){
    border-bottom: solid 1px $border-xlight;
}
@mixin hook-accordion-title(){

    overflow: hidden;
    border: solid 1px $border-light;
    border-radius: $border-radius-small;
    padding: 20px;
    font-weight: 500;

    &::after {
        content: "";
        width: ($accordion-title-line-height * 1em);
        height: ($accordion-title-line-height * 1em);
        float: right;
        @include svg-fill($internal-accordion-close-image, "#000", $accordion-icon-color);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: $accordion-icon-background-color;
        border-radius: 20px;
    }

    .uk-open > &::after { @include svg-fill($internal-accordion-open-image, "#000", $accordion-icon-color); }

}

@mixin hook-accordion-content(){
    padding: 20px;
}

@mixin hook-utility-misc(){
    .border-xlight {
        border: solid 1px $border-xlight;
    }    
    .border-light {
        border: solid 1px $border-light;
    }
    .border {
        border: solid 1px $global-border;
    }
    .border-radius-medium {
        border-radius: $border-radius-medium;
    }
    .border-radius-large {
        border-radius: $border-radius-large;
    }
    .remove-underline, .remove-underline:hover{
        text-decoration: none;
    }
    .uk-container.uk-container-xsmall {
        max-width: 700px;
    }
    .hvr-forward {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px transparent;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .hvr-forward:active,
    .hvr-forward:focus,
    .hvr-forward:hover {
        -webkit-transform: translateX(6px);
        transform: translateX(6px);
    }

    .hvr-back {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px transparent;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform;
    }

    .hvr-back:hover,
    .hvr-back:focus,
    .hvr-back:active {
      -webkit-transform: translateX(-6px);
      transform: translateX(-6px);
    }

    .social-networks {
        margin-top: $global-large-margin;
    }

    .container-xxsmall {
        max-width: $container-xxsmall;
    }

    .sidebar-fixed-width {
        width: $docs-sidebar-width;
    }
    .sidebar-docs {
        width: $docs-sidebar-width - $global-medium-gutter;
        padding-right: $global-medium-gutter;
        top: 112px;
        bottom: 70px;
        overflow-y: scroll;
        overflow-x: hidden;
        > h5 {
            margin: 15px 0 0;
            &:first-child {
                margin-top: 17px
            }
        }
    }
    
    @media (min-width: $breakpoint-large) {
        .sidebar-fixed-width {
            width: $docs-sidebar-width-l;
        }
        .sidebar-docs {
            width: $docs-sidebar-width-l - $global-large-gutter;
            padding-right: $global-large-gutter;
        }
    }
    
    ul.doc-nav {
        padding-left: 14px;
        margin-top: 5px;
    }
    
    .doc-nav > li.uk-active > a {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 15px;
            left: -14px;
            width: 7px;
            border-top: 1px solid $global-primary-background;
        }
    }
    
    .hero-image {
        margin-top: -28px;
        margin-bottom: 30px;
        img {
            max-height: 220px;
        }
    }
    
    .uk-section {
        .uk-h1 {
            margin-bottom: 12px;
        }
        .uk-text-lead {
            margin: 0;
        }
    }

    .section-hero {
        z-index: 10;
        .hero-search {
            margin: 60px auto 0 auto;
            .uk-search-input {
                height: 80px;
                border-radius: 50px;
                font-size: 1.25rem;
                font-style: normal;
                padding-left: 36px;
                &:focus {
                    background: #ffffff;
                }
            }
            .uk-search-icon {
                width: 70px;
                svg {
                    width: 31px;
                    height: auto;
                }
            }

        }
        .uk-text-lead {
            font-size: 1.25rem;
            color: #686c7b;
        }
    }

    @media (min-width: $breakpoint-small) {
        .section-videos {
            div[class^="uk-child-width-1-1"] {
                .uk-card {
                    padding-top: 70px;
                    padding-bottom: 70px;
                }
            }
        }
    }

    @media (min-width: $breakpoint-medium) {
        .section-videos {
            div[class^="uk-child-width-1-1"] {
                .uk-card {
                    padding-top: 110px;
                    padding-bottom: 110px;
                }
            }
        }
    }

    .section-featured {
        .uk-accordion>:nth-child(n+2) {
            margin-top: 16px;
        }

        a {
            display: block;
            font-size: $text-lead-font-size;
            overflow: hidden;
            border: solid 1px $border-light;
            border-radius: $border-radius-small;
            padding: 20px;
            font-weight: 500;
            color: $global-primary-background;
        
            &::after {
                content: "";
                width: ($accordion-title-line-height * 1em);
                height: ($accordion-title-line-height * 1em);
                float: right;
                @include svg-fill($featured-right-image, "#000", $accordion-icon-color);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-color: $accordion-icon-background-color;
                border-radius: 20px;
            }
        
            &:hover {
                text-decoration: none;
                color: $global-primary-background;
            }
        
        }
    }

    .section-team {
        .uk-slider-items {
            li {
                opacity: .7;
                &.uk-active {
                    opacity: 1;
                };
            }
        }
        .uk-grid > div {
            width: 128px;
        }
        .uk-card {
            img {
                max-width: 86px;
            }
        }
    }

    #markdown-toc {
        padding: 0 0 0 $global-margin;
        border-left: solid 2px #d9dde0;
        list-style: none;
        margin-bottom: $global-medium-margin;
        > li > :last-child {
            margin-bottom: 0;
        }
        ul {
            margin: 0;
            padding-left: $global-margin;
            list-style: none;
        }
        >li:nth-child(n+2), >li>ul {
            margin-top: 5px;
        }

    }
    .article-content .no_toc {
        margin-top: 0;
        margin-bottom: $global-margin;
    }

    #search-hero-results, #search-navbar-results {
        margin: 10px 0 0 0;
        box-shadow: $global-medium-box-shadow;
        border-radius: $border-radius-medium;
        background: #fff;
        li {
            margin: 0;
            line-height: 1.4;
            border-left: solid 1px $border-xlight;
            border-right: solid 1px $border-xlight;
            &:first-child {
                border-top-left-radius: $border-radius-medium;
                border-top-right-radius: $border-radius-medium;
                border-top: solid 1px $border-xlight;
            }
            &:last-child {
                border-bottom-left-radius: $border-radius-medium;
                border-bottom-right-radius: $border-radius-medium;
                border-bottom: solid 1px $border-xlight;
            }
            a {
                display: block;
                &:hover {
                    text-decoration: none;
                }
    
            }
        }
    }

    #search-hero-results {
        li {
            &:first-child {
                padding-top: 5px;
            }
            &:last-child {
                padding-bottom: 5px;
            }
            
            a {
                padding: 18px 36px;
            }
            &.no-results {
                padding: 18px 36px;
            }
        }
    }

    #search-navbar-results {
        li {
            &:first-child {
                padding-top: 2px;
            }
            &:last-child {
                padding-bottom: 2px;
            }
            a {
                padding: 14px 17px;
            }
            &.no-results {
                padding: 14px 17px;
            }
        }

    }
    
    @media (min-width: $breakpoint-small) {

        .tm-timeline {
            box-sizing: border-box;
            * {
                box-sizing: border-box;
            }
            position: relative;
    
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: calc(30% - 2px);
                bottom: 0;
                width: $timeline-border-width;
                background: $global-primary-background;
            }
    
            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }
    
        .tm-timeline-entry {
            + .tm-timeline-entry{
                margin-top: $global-large-margin;
            }
            clear: both;
            text-align: left;
            position: relative;
    
            &:after {
                display: block;
                content: "";
                clear: both;
            }
    
            .tm-timeline-time {
                float: left;
                width: 30%;
                padding-right: $global-large-margin;
                text-align: right;
                position: relative;
    
                &:before {
                    content: '';
                    position: absolute;
                    width: $timeline-dot-diameter;
                    height: $timeline-dot-diameter;
                    border: $timeline-border-width solid $global-primary-background;
                    background-color: #fff;
                    border-radius: 100%;
                    top: 0;
                    right: - 14px;
                    z-index: 99;
                }
    
                h5 {
                    margin: 3px 0 0;
                }
            }
    
            .tm-timeline-body {
                float: right;
                width: 70%;
                padding-left: $global-large-margin;
                margin-top: -2px;
                h3 {
                    margin: 0 0 15px;
                    span {
                        padding: 1px 7px;
                    }
                }
    
            }
        }
    }        

}


